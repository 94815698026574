@mixin md-desktop {
  @media (max-width: 1600px) { @content; }
}

@mixin sm-desktop {
  @media (max-width: 1280px) { @content; }
}

@mixin tablet {
  @media (min-width: 1024px) { @content; }
}

@mixin md-tablet {
  @media (min-width: 900px) { @content; }
}

@mixin phone {
  @media (min-width: 600px) { @content; }
}

@mixin md-phone {
  @media (max-width: 400px) { @content; }
}

@mixin sm-phone {
  @media (max-width: 320px) { @content; }
}


$breakpoint-sm: 600px;
$breakpoint-md: 900px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1440px;


// Mixin for media queries
@mixin respond-to($breakpoint) {
  @if $breakpoint == sm {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if $breakpoint == md {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if $breakpoint == lg {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if $breakpoint == xl {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  }
}
