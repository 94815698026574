@charset "utf-8";
@import "_settings.scss";
@import "_responsive.scss";
@import "_mixin.scss";
@import "_keyframes.scss";
@import "common.scss";


.hero-section {
  .dammy-logo-container {
    padding-top: 40px;
    width: 50%;
    .dammy-logo {
      width: 52%;
      aspect-ratio: 343 / 209;
    }
  }
  .container {
    padding-bottom: 0;
    @include respond-to(md) {
      padding-top: 206px;
      padding-bottom: 0;
    }
  }
  .headline {
    transform: none;
    margin-bottom: 16px;
    transition-delay: 1000ms;
    @include respond-to(md) {
      margin-bottom: 26px;
    }
    img {
      width: 100%;
      max-width: none;
    }
  }
  .hero-banner {
    margin-right: -4.2vw;
    margin-left: -4.2vw;
    @include respond-to(md) {
      margin-left: -40px;
      margin-right: -40px;
    }
    &__image-container {
      opacity: 0;
      transform: none;
      aspect-ratio: 100 / 71;
      background-attachment: fixed;
      background-image: url(../img/hero_banner_sm.jpg);
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position-x: center;
      transition: opacity 500ms ease 1000ms;
      @include respond-to(md) {
        aspect-ratio: 16 / 9;
        background-size: cover;
        background-image: url(../img/hero_banner.jpg);
      }
      &.executed {
        opacity: 1;
      }
    }
  }
}

.introduction-section {
  .container {
    padding-top: 40px;
    padding-bottom: 0;
    @include respond-to(md) {
      padding-top: 100px;
      padding-bottom: 0;
    }
  }
  .section-title {
    margin-bottom: 24px;
    font-size: 2.8rem;
    line-height: unit-calc(40, 28);
    @include respond-to(md) {
      margin-bottom: 0;
      font-size: 3.8rem;
      line-height: unit-calc(45, 38);
    }
  }
  .message-block {
    margin-bottom: 120px;
    @include respond-to(md) {
      margin-bottom: 260px;
    }
  }
  .row {
    .col {
      &:first-of-type {
        @include respond-to(md) {
          padding-right: 40px;
        }
      }
    }
  }
  .block {
    &__title {
      margin-bottom: 8px;
      font-size: 2.2rem;
      line-height: unit-calc(30, 25);
      @include respond-to(md) {
        margin-bottom: 12px;
        font-size: 2.5rem;
        line-height: unit-calc(30, 25);
      }
    }
    &__headline {
      margin-bottom: 20px;
      font-family: $ffBld;
      font-size: 2.2rem;
      line-height: unit-calc(30, 25);
      @include respond-to(md) {
        margin-bottom: 0;
        max-width: 455px;
        font-size: 2.5rem;
        line-height: unit-calc(30, 25);
      }
    }
    &__content {
      font-size: 1.8rem;
      line-height: unit-calc(22, 18);
      p {
        line-height: unit-calc(22, 18);
      }
    }
  }
}

.about-section {
  .container {
    padding-top: 64px;
    padding-bottom: 0;
    @include respond-to(md) {
      padding-top: 70px;
    }
  }
  .section-title {
    margin-bottom: 24px;
    font-size: 2.8rem;
    line-height: unit-calc(40, 28);
    @include respond-to(md) {
      margin-bottom: 64px;
      font-size: 3.8rem;
      line-height: unit-calc(45, 38);
    }
  }
  .row {
    &.row--reverse {
      .col {
        &:first-of-type {
          @include respond-to(md) {
            padding-right: 0;
          }
        }
        &:last-of-type {
          @include respond-to(md) {
            padding-right: 40px;
          }
        }
      }
    }
    .col {
      &:first-of-type {
        margin-bottom: 32px;
        @include respond-to(md) {
          margin-bottom: 0;
          padding-right: 40px;
        }
      }
    }
  }
  .block {
    &__title {
      margin-bottom: 16px;
      @include respond-to(md) {
        margin-bottom: 16px;
      }
    }
  }
  .oshibori-block {
    margin-bottom: 64px;
    @include respond-to(md) {
      margin-bottom: 260px;
    }
  }
  .vb-block {
    .block__content {
      margin-bottom: 32px;
      font-size: 1.8rem;
      line-height: unit-calc(22, 18);
      @include respond-to(md) {
        margin-bottom: 70px;
      }
      p {
        line-height: unit-calc(22, 18);
      }
    }
    cite {
      display: block;
      margin-left: auto;
      width: 70%;
      font-size: 1.2rem;
      font-style: normal;
      line-height: unit-calc(14, 12);
      text-align: right;
      @include respond-to(md) {
        width: 50%;
      }
    }
  }
}

.products-section {
  .container {
    padding-top: 60px;
    padding-bottom: 0;
    @include respond-to(md) {
      padding-top: 96px;
      padding-bottom: 0;
    }
  }
  .section-title {
    margin-bottom: 40px;
    font-size: 4.5rem;
    line-height: unit-calc(55, 45);
    @include respond-to(md) {
      margin-bottom: 140px;
      font-size: 7rem;
      line-height: unit-calc(80, 70);
    }
  }
  .category {
    margin-bottom: 32px;
    @include respond-to(md) {
      margin-bottom: 0;
    }
    > .col {
      &:first-of-type {
        @include respond-to(md) {
          padding-right: 40px;
        }
      }
    }
    &__name {
      margin-bottom: 8px;
      font-size: 2.8rem;
      line-height: unit-calc(40, 28);
      @include respond-to(md) {
        margin-bottom: 16px;
        font-size: 3.8rem;
        line-height: unit-calc(45, 38);
      }
      span {       
        display: inline-block;
        vertical-align: bottom;
        transform: translateY(-0.7em);
        padding: 3px 16px;
        min-width: 80px;
        font-size: 1.2rem;
        line-height: 1.2em;
        border: 1px solid #000;
        border-radius: 100px;
      }
    }
    &__description {
      margin-bottom: 32px;
      font-size: 1.7rem;
      line-height: unit-calc(22, 18);
      @include respond-to(md) {
        max-width: 445px;
        font-size: 1.8rem;
        line-height: unit-calc(22, 18);
      }
    }
  }
  .products {
    margin-right: -8px;
    margin-left: -8px;
    @include respond-to(md) {
      margin-right: -10px;
      margin-left: -10px;
    }
    .product {
      margin-bottom: 40px;
      padding: 0 8px;
      @include respond-to(md) {
        margin-bottom: 72px;
        padding: 0 10px;
      }
      &:hover {
        @media (hover: hover) {
          .product__image {
            picture {
              &:nth-of-type(2){
                opacity: 1;
              }
            }
          }
        }
      }
      &__image {
        position: relative;
        margin-bottom: 16px;
        @include respond-to(md) {
          margin-bottom: 30px;
        }
        picture {
          display: block;
          &:nth-of-type(2){
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            transition: opacity 400ms ease;
          }
        }
      }
      &__name {
        margin-bottom: 8px;
        text-align: center;
        @include respond-to(md) {
          font-size: 2.5rem;
          line-height: unit-calc(30, 25);
        }
      }
      &__specs {
        font-size: 1.2rem;
        line-height: unit-calc(14, 12);
        text-align: center;
      }
    }
  }
}

.company-section {
  .container {
    padding-top: 60px;
    @include respond-to(md) {
      padding-top: 70px;
    }
  }
  .section-title {
    margin-bottom: 24px;
    font-size: 2.8rem;
    line-height: unit-calc(40, 28);
    @include respond-to(md) {
      font-size: 3.8rem;
      line-height: unit-calc(45, 38);
    }
  }
  .section-content {
    @include respond-to(md) {
      font-size: 1.8rem;
      line-height: unit-calc(22, 18);
    }
    p {
      line-height: unit-calc(22, 18);
    }
    ul {
      margin-left: 0.4em;
      max-width: 400px;
      li {
        text-indent: -0.4em;
        &::before {
          content: "-";
        }
      }
    }
  }
}