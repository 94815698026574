@keyframes fade-in {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    display: block;
    opacity: 1;
  }
  99% {
    display: block;
    opacity: 0;
  }
  100% {
    display: none;
    opacity: 0;
  }
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes menu-in {
  0% {
    visibility: hidden;
    transform: translateX(100%);
  }
  1% {
    visibility: visible;
    transform: translateX(100%);
  }
  100% {
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes menu-out {
  0% {
    visibility: visible;
    transform: translateX(0);
  }
  99% {
    visibility: visible;
    transform: translateX(100%);
  }
  100% {
    visibility: hidden;
    transform: translateX(100);
  }
}

@keyframes menu-in-side {
  0% {
    display: none;
    visibility: hidden;
    transform: translateX(100%);
  }
  1% {
    display: block;
    visibility: visible;
    transform: translateX(100%);
  }
  2% {
    display: block;
    visibility: visible;
    transform: translateX(100%);
  }
  100% {
    display: block;
    visibility: visible;
    transform: translateX(0);
  }
}

@keyframes menu-out-side {
  0% {
    display: block;
    visibility: visible;
    transform: translateX(0);
  }
  98% {
    display: block;
    visibility: visible;
    transform: translateX(100%);
  }
  99% {
    display: block;
    visibility: hidden;
    transform: translateX(100%);
  }
  100% {
    display: none;
    visibility: hidden;
    transform: translateX(100%);
  }
}

@keyframes indicator {
  0% {
    top: 0;
		height: 0;
  }
  70% {
		height: 120px;
  }	  
  100% {
    top: 120px;
		height: 120px;
  }	
  // 51% {
  //   top: auto;
  //   bottom: 0;
	// 	height: 120px;
  // }	  
  // 100% {
  //   top: auto;
  //   bottom: 0;
	// 	height: 0;
  // }
}